.card {
  @extend .paw;
  margin-bottom: 1.875rem;
  height: calc(100% - 1.875rem);

  h2 {
    font-size: rem-calc(24);
  }

  .card-image {
    @extend .paw-image;
  }
}