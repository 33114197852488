.subteaser-list {
	margin-top: rem-calc(50);

	.cell {
		margin-bottom: rem-calc(30);

		@include breakpoint(large) {
			margin-bottom: 0;
		}
	}
}

.subteaser {
	display: flex;
	flex-direction: column;
	position: relative;
	height: 100%;
	padding: rem-calc(20);
	border: 0.5rem $light-gray solid;
	background: $white;
	transition: all 0.25s ease;

	p:last-child {
		margin-bottom: 0;
		margin-top: auto;
	}

	&:hover {
		@include breakpoint(large) {
			transform: rotate(-5deg);
		}

		a.more:before {
			width: 100%;
		}
	}
}