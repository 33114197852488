body {
  font-weight: $global-weight-light;
  background: #eeeeee;
}

a {
  transition: $global-transition;
}

img {
  width: 100%;
  height: auto;
}