table {
	border-collapse: separate;

	td, th {
		border-bottom: 1px solid $light-gray;
	}

	thead th {
		padding-top: 0;
		background: $white;
	}

	.price {
		@extend .text-right;
		white-space: nowrap;
	}
}