@mixin breakpoint-values($property, $small, $medium, $large) {
  #{$property}: $small;

  @include breakpoint(medium) {
    #{$property}: $medium;
  }

  @include breakpoint(large) {
    #{$property}: $large;
  }
}