.gallery {
	@extend .mt-medium;
	display: flex;
	flex-wrap: wrap;
	margin-left: rem-calc(-5px);
	margin-right: rem-calc(-5px);

	> a {
		width: 50%;
		padding: rem-calc(5px);

		@include breakpoint(medium) {
			width: 33.333%;
		}

		@include breakpoint(large) {
			width: 25%;
		}

		img {
			transition: all 0.2s ease-in-out;
			box-shadow: 0 0 0 rgba($black, 0.8);
		}
	}

	> a:hover img {
		transform: rotate(-7deg);
		box-shadow: 0 0 0.4rem rgba($black, 0.8);
	}
}