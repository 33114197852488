h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-bottom: 1em;
}

a.more {
  position: relative;
  display: inline;

  &:before {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 0;
    height: 2px;
    background: $primary-color;
    transition: width 0.3s ease;
  }

  &:hover {
    color: $primary-color;

    &:before {
      width: 100%;
    }
  }
}

a.icon-link {
  display: block;
  padding: 0.5rem 1rem 0.5rem 0.2rem;

  i {
    display: inline-block;
    width: 1.5rem;
  }

  &:hover {
    padding-left: 1rem;
    color: $white;
    background: get-color(primary);
  }

  &.small {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }

  &.inline {
    @include breakpoint(medium) {
      display: inline-block;
    }
  }
}