.reviews {
	@extend .mb-medium;
	border: 1px solid $light-gray;
	padding: 1rem;


	#review-container {
		font-size: rem-calc(16px);

		h3 {
			font-family: $body-font-family;
		}

		.romw {
			padding-bottom: rem-calc(20px);
			border-bottom: 1px solid $light-gray;
			margin-bottom: rem-calc(20px);
		}
	}

	.romw .romw-source-logo img {
		width: auto;
	}
}