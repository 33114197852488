.paw {
  position: relative;

  .paw-image {
    position: relative;

    span {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: linear-gradient(45deg, rgba($white, 0.4) 0%,rgba($white, 0.6) 100%);
      opacity: 0;
      transition: all 0.5s ease;

      @include flex;
      @include flex-align(center, middle);
    }

    i {
      font-size: rem-calc(25);
      color: $white;
      text-shadow: 0 0 rem-calc(10) rgba($black, 0.3);
      transition: all 0.5s ease;
    }
  }

  &:hover {
    a.more:before {
      width: 100%;
    }

    .paw-image span {
      opacity: 1;

      i {
        font-size: rem-calc(100);
      }
    }
  }
}