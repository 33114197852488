.linkbox {
	@extend .mb-medium;
	border: 1px solid $light-gray;
	padding: 1rem;

	ul {
		list-style: none;
		padding: 0;
		margin: 0;

		li a {
			@extend .icon-link;
		}
	}
}