.button {
	font-weight: 500;

	&.hollow {
		background: rgba($white, 0.5);

		&:hover {
			color: $white;
			background: get-color(primary);
		}

		&.negative {
			color: $white;
			border-color: $white;
			background: rgba($black, 0.3);
		}
	}

	> i {
		margin-right: 0.5rem;
	}
}