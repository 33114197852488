.top-bar {

}

.dropdown.menu {
	align-items: stretch;

	.menu-text {
		padding: 0 3rem 0 0;
		margin: 0 auto 0 0;

		a {
			padding: 0;

			&:before {
				display: none;
			}
		}
	}

	> li {
		margin-left: 2.5rem;
		display: flex;

		&:nth-child(2) {
			margin-left: 0;
		}

		> a {
			@extend .more;
			align-self: center;
			color: $black;

			&:hover {
				color: get-color(primary);
			}
		}

		&.active {
			> a {
				color: get-color(primary);
			}
		}
	}
}

.submenu.menu {
	min-width: rem-calc(250px);
	padding: 0;
	margin: 1rem 0 0 -1rem;
	background: transparent;

	li {
		margin: 0 0 1px 0;

		a {
			color: $black;
			padding: 0.75rem 1rem;
			background: rgba($white, 0.7);
		}

		&.active a,
		a:hover {
			color: $white;
			background: get-color(primary);
		}
	}
}

nav.main-mobile {
	.top-bar-left {
		max-width: 70%;
	}

	.top-bar-right {
		max-width: 30%;
		text-align: right;
	}

	.burger-icon {
		font-size: rem-calc(30px);
		cursor: pointer;

		i.close {
			display: none;
		}

		&.active {
			color: $primary-color;

			i.close {
				display: block;
			}

			i.open {
				display: none;
			}
		}
	}

	#mobile-nav {
		display: none;
	}

	.accordion-menu {
		> li {
			border-top: 1px solid $medium-gray;

			> ul {
				margin-top: 0;
				margin-bottom: rem-calc(10px);
			}
		}

		a {
			color: $body-font-color;
			padding: $accordionmenu-padding;
			background: transparent;
		}

		li.active > a,
		a:hover {
			color: $primary-color;
			background: transparent;
		}
	}
}

nav.misc .menu {
	padding: rem-calc(40px 0);

	li {
		width: auto;
		display: inline;
		margin: 0 0 0.5rem 0;

		@include breakpoint(large) {
			margin: 0 2rem 0 0;

			&:last-child {
				margin-right: 0;
			}
		}

		&.menu-text {
			margin: 1.5rem 0 0 0;
			font-weight: 300;
			order: 1;

			@include breakpoint(large) {
				order: 0;
				margin: 0 auto 0 0;
			}
		}

		&.active a {
			color: get-color(primary);
		}
	}

	a {
		@extend .more;
		color: $black;

		@include breakpoint(large) {
			display: block;
		}
	}
}