.news-list {
  margin-bottom: rem-calc(130);
}

.news-item {
  @extend .paw;
  margin-bottom: rem-calc(40);

  @include breakpoint(large) {
    margin-bottom: rem-calc(20);
  }

  .news-image {
    @extend .paw-image;
    margin-bottom: rem-calc(30);

    @include breakpoint(medium) {
      margin-bottom: 0;
    }

    img {
      border-radius: 50%;
    }
  }

  &:nth-child(even) {
    @extend .medium-text-right;

    > div {
      @include flex-direction(row-reverse);
    }
  }
}