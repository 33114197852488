.stage {
	position: relative;

	.stage-img {

	}

	.stage-text {
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		width: 100%;
		margin: 0 auto;
		color: $white;

		@include breakpoint(medium) {
			justify-content: center;
		}
	}

	.inner {
		text-shadow: 0 0 0.8rem rgba($black, 0.7);

		@include breakpoint(medium) {
			width: 66%;
		}

		@include breakpoint(xlarge) {
			width: 50%;
		}
	}

	.cta {
		margin-top: rem-calc(30px);
		display: none;

		@include breakpoint(medium) {
			display: block;
		}
	}

	.stage-button {
		@extend .button;
		@extend .hollow;
		@extend .negative;
	}

	h1 {
		@include breakpoint(xlarge) {
			font-size: rem-calc(45px);
		}
	}
}

.stage.subpages {
	h1 {
		font-size: rem-calc(35px);
		margin-bottom: 0;

		@include breakpoint(medium) {
			font-size: rem-calc(55px);
		}

		@include breakpoint(large) {
			font-size: rem-calc(65px);
		}

		@include breakpoint(xlarge) {
			font-size: rem-calc(75px);
		}
	}

	.inner {
		width: 100%;

		@include breakpoint(medium) {
			width: 100%;
		}

		@include breakpoint(xlarge) {
			width: 75%;
		}
	}

	.stage-text {
		justify-content: center;
	}
}