ul.list-circle {
	list-style: none;
	padding-left: 0;
	margin-left: 0;

	li {
		position: relative;
		padding-left: 1rem;
		margin-bottom: 0.25rem;

		&:before {
			content: "•";
			position: absolute;
			left: 0;
			top: 0;
			font-size: 1.7em;
			line-height: 1.5rem;
			color: get-color(primary);
		}
	}
}