.page {
	width: 100%;
	max-width: 1920px;
	margin: auto;
	background: $white;
	box-shadow: 0 0 1rem rgba($black, 0.3);
}

.logo {
	width: 18rem;
	height: auto;
}

section.main {
	margin: rem-calc(50px 0);

	@include breakpoint(medium) {
		margin: rem-calc(100px 0);
	}

	@include breakpoint(large) {
		margin: rem-calc(100px 0);
	}
}

section.cards {
	padding: rem-calc(70 0 40 0);
	background: $light-gray;
}

