.mb-small {
	@include breakpoint-values(margin-bottom, rem-calc(20px), rem-calc(30px), rem-calc(30px));
}

.mb-medium {
	@include breakpoint-values(margin-bottom, rem-calc(30px), rem-calc(40px), rem-calc(50px));
}

.mb-large {
	@include breakpoint-values(margin-bottom, rem-calc(50px), rem-calc(70px), rem-calc(70px));
}

.mt-small {
	@include breakpoint-values(margin-top, rem-calc(20px), rem-calc(30px), rem-calc(30px));
}

.mt-medium {
	@include breakpoint-values(margin-top, rem-calc(30px), rem-calc(40px), rem-calc(50px));
}

.mt-large {
	@include breakpoint-values(margin-top, rem-calc(50px), rem-calc(70px), rem-calc(70px));
}